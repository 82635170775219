// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //tokenUrl: 'https://localhost:44327/Token',
  //baseUrl:'https://localhost:44327/api/Workez'
  
   tokenUrl: 'http://35.202.198.25:81/WorkezAPIDev/Token',
  //  baseUrl:'http://35.202.198.25:81/WorkezAPIDev/api/Workez',

   baseUrl:'https://passcoverageapi.azurewebsites.net/api/',
  // baseUrl:"http://localhost:3000/api/"

  
  // tokenUrl: 'http://35.202.198.25:81/WorkezAPIQA/Token',
  // baseUrl:'http://35.202.198.25:81/WorkezAPIQA/api/Workez'  

  // tokenUrl: 'http://35.202.198.25:81/WorkezAPIUAT/Token',
  // baseUrl:'http://35.202.198.25:81/WorkezAPIUAT/api/Workez'  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
