import { ElementSchemaRegistry } from '@angular/compiler';
import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  UrlSegment,
  CanActivateChild,
  CanDeactivate,
  CanLoad
} from '@angular/router';
import { Observable } from 'rxjs';
import { Page404Component } from 'src/app/authentication/page404/page404.component';
import { Roles } from 'src/Classes/user-roles';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //   debugger;
    // let authorities = route.data.roles as Roles[];
    // console.log(authorities);
    // if (this.authService.hasAnyRole(authorities)) {
    //     return true;
    // }
    // return false;

    return true; // TO DO


    if(this.authService.isLoggedIn()){
        return true;
    }
    alert('You have not logged in!')
    this.router.navigate(['/authentication/signin']);
    return false;
 }
//  canLoad(route: Route) {
//     let authorities = route.data.roles as Roles[];
//     if (this.authService.hasAnyRole(authorities)) {
//         return true;
//     }
//     return false;
// }
// canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
//     debugger;
//   let authorities = route.data.roles as Roles[];
//   console.log(authorities);
//   if (this.authService.hasAnyRole(authorities)) {
//       return true;
//   }
//   return false;
// }



//   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//     if (!this.authService.isAuthorized()) {
//         this.router.navigate(['login']);
//         return false;
//     }

//     const roles = route.data.roles as Roles[];
//     if (roles && !roles.some(r => this.authService.hasRole(r))) {
//         this.router.navigate(['error', 'not-found']);
//         return false;
//     }

//     return true;
// }

// canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
//     if (!this.authService.isAuthorized()) {
//         return false;
//     }
// debugger;
//     const roles = route.data && route.data.roles as Roles[];
//     if (roles && !roles.some(r => this.authService.hasRole(r))) {
//         return false;
//     }

//     return true;
// }
  
 
    
  

}
