import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard/main',
    title: 'Dashboard',
    moduleName: 'dashboard',
    icon: 'fas fa-tachometer-alt',
    class: '',
    groupTitle: false,
    submenu: []
  }  
  ,{
    path: '',
    title: 'Company',
    moduleName: 'company',
    icon: 'fas fa-building',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
          {
            path: '/company/companydetails',
            title: 'Companies',
            moduleName: 'company',
            icon: '',
            class: '',
            groupTitle: false,
            submenu: []
          },
          {
            path: '/company/members',
            title: 'Members',
            moduleName: 'company',
            icon: '',
            class: '',
            groupTitle: false,
            submenu: []
          }
      ]
    }   
];
