import { FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators{
    nameValidator(control:FormControl):{[key:string]:boolean}{
        const nameRegexp:RegExp=/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        if(control.value && nameRegexp.test(control.value)){
            return {
                invalidName:true
            };
        }
    }
    // checkLimit(min: number, max: number): ValidatorFn {
    //     return (c: FormControl): { [key: string]: boolean } | null => {
    //         if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
    //             return { 'range': true };
    //         }
    //         return null;
    //     };
    //   }

    cannotContainSpace(control: FormControl) : ValidationErrors | null {
        if(control.touched){
        if((control.value as string).indexOf(' ') >= 0){
            return {cannotContainSpace: true}
        }
    }
  
        return null;
    }
}