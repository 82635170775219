import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Roles } from 'src/Classes/user-roles';
import { Page404Component } from './authentication/page404/page404.component';
import { AdminlevelGuard } from './core/guard/adminlevel.guard';
import { AuthGuard } from './core/guard/auth.guard';
import { RoleGuard } from './core/guard/role.guard';
import { AuthService } from './core/service/auth.service';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data:{roles:['SuperAdmin','Admin','User']},
    //!/node_modules/@angular/compiler/src/core.d.ts------Added data:any to Route
    
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
       // canLoad:[AuthGuard],
        //canActivate:[AuthGuard],       
       // canActivateChild:[AuthGuard],
       //canActivate:[RoleGuard],
        data:{roles:[Roles.SuperAdmin,Roles.Admin]},
        //data:{roles:['SuperAdmin','Admin']},
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },     
      {
        path: 'company',
       // canLoad:[AuthGuard],
        //canActivate:[AuthGuard],
       // canActivateChild:[AuthGuard],
        //data:{roles:[]},
        
        data:{roles:[Roles.SuperAdmin,Roles.Admin,Roles.User]},
        loadChildren: () =>
          import('./company/company.module').then(
            (m) => m.CompanyModule
          )
      }
      ,
      {path:'profile',
      loadChildren: () =>
      import('./profile/profile.module').then(
        (m) => m.ProfileModule
      )
    } 
    ]
   }
  ,{
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  } , 
  { path: '**', component: Page404Component ,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
  //,providers:[AuthGuard,AuthService]
})
export class AppRoutingModule {}
