import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Roles } from 'src/Classes/user-roles';
import { UserRole } from 'src/Classes/UserRole';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogin = false;    
      roleAs: string;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  AccessToken:string="";
  //private TokenAPI="/api";
  
  private TokenAPI=environment.tokenUrl;
  //private TokenAPI="https://localhost:44327/Token";
  //private TokenAPI="http://35.202.198.25:81/WorkezAPIDev/Token";
  //private TokenAPI="http://35.202.198.25:81/WorkezAPIQA/Token";
  

  constructor(private http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem('currentUser'))
    // );
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  setCustomer(lastName: string, bookingRefNo: string) {
    this.isLogin = true;
   localStorage.setItem('lastName', lastName);
   localStorage.setItem('bookingRefNo',bookingRefNo);
   return true;
  }


  login(username: string, password: string) {
    this.isLogin = true;
    var headersForTokenAPI=new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
    var data="grant_type=password&username="+username+"&password="+password;
    //console.log(username+' '+password)
   //console.log(this.TokenAPI);
    return this.http
      .post<any>(this.TokenAPI, data, {headers:headersForTokenAPI})
      .pipe(
        map((user) => {
          debugger;        
         
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user.access_token));
          localStorage.setItem('currentToken', user.access_token);
          localStorage.setItem('currentUserID', user.userID);
          localStorage.setItem('currentAccountID', user.accountId);
          localStorage.setItem('currentUserName', user.userName);
          localStorage.setItem('currentRole', user.roleName);
          localStorage.setItem('STATE', 'true');
          this.currentUserSubject.next(user);
          this.roleAs=user.roleName;    
          
         
          this.user = { Role: user.roleName };//04082021

          return user;
        }),
        catchError((err, caught) => {
        //  throw new Error("OK");
        throw err;
          // debugger;
          // if(err=="OK"){
          //   err="Email or password is incorrect"
          // }         
          return err;
        })
      );
  }

  isLoggedIn() {
    // const loggedIn = localStorage.getItem('STATE');
    // if (loggedIn == 'true')
    //   this.isLogin = true;
    // else
    //   this.isLogin = false;
    // return this.isLogin;
    return !!localStorage.getItem('currentToken');
  }

  getRole() {
    this.roleAs = localStorage.getItem('currentRole');
    return this.roleAs;
  }

  logout() {
    // remove user from local storage to log user out
    //debugger;
    this.isLogin = false;
        this.roleAs = '';

    localStorage.removeItem('currentUser');
    localStorage.setItem('STATE', 'false');
    localStorage.setItem('ROLE', '');

    localStorage.removeItem('currentUserID');
    localStorage.removeItem('currentAccountID');
    localStorage.removeItem('currentUserName');
    localStorage.removeItem('currentRole');
    localStorage.removeItem('currentToken');

    this.currentUserSubject.next(null);
    window.localStorage.clear();
    window.localStorage.removeItem('currentUserID');

    console.log( localStorage.getItem('currentUserID'))
    //return of({ success: false });

    this.user = null;//04082021

    return of({ success: this.isLogin, role: '' });
  }

  getAllModules()
   {    
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': 'Bearer '+ localStorage.getItem('currentToken')
    }
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    return this.http.get<any>('https://localhost:44327/api/Workez/GetModulesByRole?RoleID=1', requestOptions);
   // return this.http.get<any>('http://35.202.198.25:81/WorkezAPIDev/api/Workez/GetAllLocations?AccountID=1', requestOptions);
   }
//04082021
  private user:UserRole;
  isAuthorized() {
    return !!this.user;
}
hasRole(role: Roles) {
 // debugger;
  return this.isAuthorized() && this.user.Role === role;
}


hasAnyRole(roles: string[]): boolean {
  //console.log(roles);
  for (let i = 0; i <= roles.length; i++) {
      if (this.hasRole1(roles[i])) {
          return true;
      }
  }
  alert('you dont have permission to access')
  return false;
}
hasRole1(role: string): boolean {
  //let authorities = Roles[];
 // return authorities.findIndex(a => a === role) > -1;
 console.log("hasRole1 method : "+ this.user.Role);
 console.log("hasRole1 method : "+ role);
 return this.user.Role === role;
}
//04082021


public get currentUserval(): string {
  return localStorage.getItem('currentToken');
}
    
   
}
